import { render, staticRenderFns } from "./theory.vue?vue&type=template&id=edda95ec&scoped=true"
import script from "./theory.vue?vue&type=script&lang=js"
export * from "./theory.vue?vue&type=script&lang=js"
import style0 from "./theory.vue?vue&type=style&index=0&id=edda95ec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edda95ec",
  null
  
)

export default component.exports